import React from "react";
import { graphql } from "gatsby";

import { makeStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";

//import csx from "csx";
import _ from "lodash-core";

import Layout from "../components/layout";
import Navigation from "../components/Navigation";

const useStyles = makeStyles(theme => ({
    heading: {
        fontSize: theme.typography.pxToRem(17),
        fontWeight: theme.typography.fontWeightRegular,
    }
}));

export default function Datenschutz({ data }) {
    const classes = useStyles();

    const entries = data.allMarkdownRemark.edges || [];
    const [expanded, setExpanded] = React.useState(_.get(entries, [0, "node", "id"]));

    const handleChange = panel => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    return (
        <Layout header="Nutzungsbedingungen">
            <Navigation path="/nutzungsbedingungen" />
            {
                (entries)
                    .map((e) => ( e.node))
                    .map(( n, ind ) => (
                        <ExpansionPanel
                            key={ ind }
                            expanded={expanded === n.id}
                            onChange={handleChange(n.id)}
                        >
                            <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                            >
                                <Typography className={ classes.heading }>
                                    { n.frontmatter.question }
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <div className="answer nutzungsbedigungen"
                                    dangerouslySetInnerHTML={ { __html: n.html } }
                                />
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    ))
            }
        </Layout>
    );
}

export const query = graphql`query {
allMarkdownRemark(
   filter: {
        frontmatter: {tags: {in: "nutzungsbedingungen"}}
   },
   sort: {
        fields: fileAbsolutePath,
        order: ASC
   }
) {
    edges {
      node {
        id
        parent {
          id
        }
        frontmatter {
          title
          question
        }
        html
      }
    }
  }
}`;
